
import logo from '../images/logo.png'
import LJ from '../images/LJ.png'
import TW from '../images/TW_.png'
import TW2 from '../images/TW-2.png'
import Rapsody from '../images/Rapsody.png'
import BR from '../images/BR.png'


import ML from '../images/ML_.png'
import Ambre from '../images/AMBRE.png'
import SH from '../images/SH.png'
import TP from '../images/TP.png'

import KL from '../images/KL.png'
import SAYGRACE from '../images/SAYGRACE.png';
import asiahnImg from '../images/Asiahn.png';
import bkHabermehlImg from '../images/BK.png';


import LJVideoMov from '../videos/LJ-1.mov'
import LJVideo from '../videos/LJ.webm'

import TPVideo from '../videos/tayla.webm'
import TPVideoMov from '../videos/TP-1.mov'

import SayGraceVideo from '../videos/saygrace.webm'
import SayGraceVideoMov from '../videos/Saygrace-1.mov'

import TWVideo from '../videos/tierrawhack.webm'
import TWVideoMov from '../videos/TW-1.mov'

import MLVideo from '../videos/ML.webm'
import MLVideoMov from '../videos/ML-1.mov'

import Rhapvideo from '../videos/rhapsody.webm'
import RhapvideoMov from '../videos/rhap-1.mov'

import KLvideo from '../videos/KL.webm'
import KLvideoMov from '../videos/KL-1.mov'

export const taylaParx = {
    artist:'Tayla Parx',
    title:'Mother Nature',
    song_url:"https://open.spotify.com/embed/track/0Cyp9kufRZOCwvcTLLIuWq",
    webm: TPVideo,
    hevc: TPVideoMov,
    desc:`GRAMMY® Award-nominated singer, songwriter, and boundary breaker Tayla Parx reimagines genre, gender, feminism, popular music, and the very definition of an artist. 
    <strong>"In my fashion and in my music, the philosophy is to defy every rule we've placed on ourselves before. Let's redefine genre & gender. Rethink feminism, reshape R&B and popular music What I do sounds just like my personality. It's fun, loud and quirky. It's my colorful world."Within one year, she became “the first female songwriter to have three simultaneous top 10 songs in the Billboard Hot 100 since 2014.”</strong>`
}


export const laurenJuaregui = {
    artist:'Lauren Jauregui',
    title:'While I’m Alive',
    song_url:"https://open.spotify.com/embed/track/0ajbF8tQsSRxswoY9ZFtOg",
    hevc: LJVideoMov,
    webm: LJVideo,
    desc:`Lauren Jauregui is an artist, singer, songwriter, creative and humanitarian. True to her artistic ethos, this next phase of Jauregui’s career finds her fully expressing her creativity in every aspect of her art, from writing songs to conceptualizing and editing videos. <strong>"For me, coming into my own and being comfortable with myself really changed me as a person and made me more confident and vibrant. It's about visibility. It's about standing in that visibility and in the strength of being able to walk with full power in who you are, who you truly are, not who the world wants to see you as."</strong>`
}

export const tierraWhack = {
    artist:'Tierra Whack',
    title:'Who Knew',
    song_url:"https://open.spotify.com/embed/track/03MZafkWFQhFuS2pZ3tuKA",
    webm:TWVideo,
    hevc:TWVideoMov,
    desc:`Tierra Whack is a Philadelphia native whose theatrical, undeniably unique take on contemporary rap and R&B has received widespread acclaim. Whack began writing poetry as a child and she started rapping as a teenager. However, she wasn't satisfied with her work, and was having trouble developing an individual style. After years of perfecting her craft, in 2018 she released her debut album which was a major critical success. <strong>"I started music to be myself - to release and express - so I have to make sure that I'm staying true to me and making myself happy. Being a female in music I just, I want everybody to, all the women to come in, and we huddle up and we work together and we help each other."</strong>`
}

export const tierraWhack2 = {
    artist:'Tierra Whack',
    title:'Who Knew',
    song_url:"https://open.spotify.com/embed/track/03MZafkWFQhFuS2pZ3tuKA",
    image: TW2,
    desc:`Tierra Whack is a Philadelphia native whose theatrical, undeniably unique take on contemporary rap and R&B has received widespread acclaim. Whack began writing poetry as a child and she started rapping as a teenager. However, she wasn't satisfied with her work, and was having trouble developing an individual style. After years of perfecting her craft, in 2018 she released her debut album which was a major critical success. <strong>"I started music to be myself - to release and express - so I have to make sure that I'm staying true to me and making myself happy. Being a female in music I just, I want everybody to, all the women to come in, and we huddle up and we work together and we help each other."</strong>`
}

export const rapsodyInfo = {
    artist:'Rapsody',
    title:'Iconic',
    song_url:"https://open.spotify.com/embed/track/1JShNYPrdwzzEL0sh3OCWn",
    webm:Rhapvideo,
    hevc: RhapvideoMov,
    desc:`Rapsody is a 3X GRAMMY nominated emcee hailing from North Carolina with critically acclaimed music here to stay. The phrase, “Give me my flowers while I can still smell them,” is a phrase spoken by Rapsody’s grandmother and resonates throughout her artistry. Rapsody celebrates the beauty, strength, and will of Black women through songs titled after iconic women including this track which pays homage to Mary J Blige. <strong>“I've learned more about life, I've learned more about myself, about the music business, about having legacy and longevity. You begin to see who you are and you own it and you become OK with it. I've found my lane and I know my place in the music. I know where I fit and you just walk it proudly. That's kind of where I am."</strong>`
}

export const kianaLede = {
    artist:'Kiana Ledé',
    title:'Cut `Em Off',
    song_url:"https://open.spotify.com/embed/track/34Ajnr1Pu9mhPDT6FENTHB",
    webm: KLvideo,
    hevc: KLvideoMov,
    desc:`Born to a Mexican and Native Amercdican Self-raised mother, and an African American and Native American father, Kiana developed a sense of identity that fuels her art to this day. <strong>“I hope people feel the vulnerability and truth in my music and see it as something strong, I hope they find self-awareness and self-worth in my music. I hope they hear my story and my struggles and find something to take away from it that makes them feel powerful. I want women to know that you can be vulnerable and strong—that you can be in control and not be taken advantage of. Most importantly, I want everyone who hears my music to know that I am no victim. I am in control of my own destiny and path and I embrace all the flaws and mistakes I have made because they make up who I am.”</strong>`
}

export const bbRose = {
    artist:'Baby Rose',
    title:'At Your Worst',
    song_url:"https://open.spotify.com/embed/track/48Y7zjtKY22gn9xRWnaiDh",
    image: BR,
    desc:`With a voice seasoned and vintage as Baby Rose's, everything she utters reverberates like the gospel truth. The D.C. native, raised in Fayetteville, N.C. came into her own as an artist in Atlanta. Baby Rose started recording music with her piano at the age of 9. <strong>"I would not be able to write with such emotion about these things without my fair share of regrets".</strong> she says.`
}

export const muniLongInfo = {
    artist: 'Muni Long',
    title: 'Plain Jane',
    song_url:"https://open.spotify.com/embed/track/2i5s0XsdoPApHvmdwzuxoC",
    webm:MLVideo,
    hevc:MLVideoMov,
    desc:`After a decade of writing multiplatinum smashes for various superstars, Priscilla Renea
    manifests her future as Muni Long. Co-mingling R&amp;B, rap, and pop, she beckons immersion with
    a stirring, striking, and soulful signature style. An unbelievable journey brought her to this
    point. It started behind-the-scenes as a songwriter, where she accumulated a diverse catalog of
    hits, but after a series of intimate and open conversations she decided to level up as a creator
    and actualize her innermost ambitions, adopting the name Muni Long and embarking on a solo
    project. “Priscilla is the name I was born with” she says. <strong> “I always wanted to be Muni Long
    though. She’s fearless. She has intent. She is me.I’m that little girl sitting in front of the TV
    watching Aaliyah’s ‘Are You That Somebody’, and I want to be that person for somebody
    else.”</strong>`
}

export const ambreInfo = {
    artist: 'Ambré',
    title: 'What You Deserve Remix ft. 6LACK',
    song_url:"https://open.spotify.com/embed/track/7xSPwpcoOQ3upwGldVdZzv",
    image: Ambre,
    desc:`Born and raised in New Orleans, LA, Ambré has already built an impressive career as a multi-faceted artist and Grammy award winning writer. Always artistic, Ambré is a self-taught musician who learned to play instruments, sing, write, produce, and draw all while growing up in foster care. <strong>"Me becoming more comfortable had a lot to do with me listening to singers like Brandy. Brandy is one of my favorite artists because she’s such a good singer, but she doesn’t have to do too much to let you know. I basically copied her! When I first started singing, I just copied Brandy, and that’s how I got more confident in myself and started to find my own voice."</strong><br>
    <br>
    Pronounced “black”, alternative R&B artist 6LACK has long considered six an important number that is central to his identity. Raised in Atlanta’s Zone 6 (hence 6LACK), the musician attributes his hometown as a major influence on his stage name as well as the spiritual significance of the number six.
    `
}

export const sineadHarnettInfo = {
    artist: 'Sinéad Harnett',
    title: 'Crown',
    song_url:"https://open.spotify.com/embed/track/0XvH3lHe3SyosbLAygBMQZ",
    image: SH,
    desc:`Music has always been the most stable force in Sinéad’s life. It was a piano that became a cathartic outlet for her while growing up in North London and provided the therapeutic company that she so desperately craved from her separated parents. The daughter of a Thai mother and an Irish father, Sinéad has always been the archetype of a blended identity. <strong>"I had a pretty rough time as a child, but music always pulled me through… I would sit, cry, play and sing to get me through the loneliness. I think music and singing gave me hope that I could be worthy of something one day, and so my hope is to make someone feel worthy, loved and healed through my own.</strong>”
    `
}
export const sayGrace = {
    artist: 'SAYGRACE',
    title: 'If There Really Is A God',
    song_url:"https://open.spotify.com/embed/track/0AGXwKZOAHFlGZ5krgZ78U",
    webm: SayGraceVideo,
    hevc: SayGraceVideoMov,
    desc:`A limber and soulful-voiced singer from Australia, Grace Sewell, who records simply as SayGrace, exhibits a strong Amy Winehouse influence in her commanding retro-contemporary R&B style. <strong>"I think my favorite part about being a writer is, for the most part, being able to, like, connect with the listener in a way that - sometimes we're able to articulate how somebody is feeling when they can't do it for themselves. And all my favorite songs throughout history have done that for me. Like, when I have just been feeling a certain type of way and I don't know how to get the words out myself, and then a song will just say so perfectly what is going on in my mind or my heart at the time. And I think, as a writer, that's kind of like the greatest gift that you can give to people."</strong>`
}

export const asiahn = {
    artist: 'Asiahn',
    title: 'What She Wants ft. Elhae',
    song_url:'https://open.spotify.com/embed/track/5fwYvJZ8hb7jJzKLtx2oR3',
    desc:`
    Jersey-born, Carolina-raised multi-hyphenate Asiahn—pronounced “Ahh-zee-yahn”—says it’s about what we do with the time between heartbreak and new love that matters most. “We need a break,” she says. “We need to live. And we need to remember who we are.”
    <br>
    <br>
    ELHAE is among the influx of artists that has blurred the distinction between alternative and commercial R&B with casual if deeply heartfelt vocals over lean productions with leisurely tempos.
    `,
    image: asiahnImg,
}

export const bkHabermehl = {
    artist: 'B.K. Habermehl',
    title: 'Jordan Hawkins & B.K. Habermehl  “Your Window”',
    song_url: 'https://open.spotify.com/embed/track/6F11iOx6WgacGsAFQE3DIk',
    desc: `With a voice as unique as her name, B.K. Habermehl fuses genres with a R&B sensibility, hip-hop bravado, and jazzy flair into a one of a kind soundscape, punctuated by evocative lyrics delivered precisely to the sweet spot of every track’s pocket. “My music’s emotional, not always down necessarily but that's what the whole point of music is: trigger genuine emotions. That's why I create little getaway vibes where you're in the song.”
    <br>
    <br>
    Jordan Hawkins, the R&B’s 24 year-old rockstar didn’t just appear overnight. This project and his stunning sound have both come together through years of passion and work, fusing together so many influences, inspirations, and collaborators.`,
    image: bkHabermehlImg
}

export const bfeInfo = {
    artist: 'Big Femme Energy',
    title: '',
    song_url:'',
    image: logo,
    desc:`Big Femme Energy Volume 1 Deluxe is an extension of Volume 1, featuring duets with chart-topping male artists celebrating women. <br><br><strong><a href="https://ffm.to/bigfemmeenergyvol1deluxe" target="_blank" class="btn"> Click here to listen to the album.</a></strong>
    <br><strong><a href="https://www.youtube.com/channel/UCg5bM-YL_Alhilpha58bpZg/featured" target="_blank" class="btn"> Click here to watch videos.</a></strong>
    `
}

export const femmeitForwardInfo = {
    artist: 'Next Gem Femme',
    desc: `Next Gem Femme was created to empower and increase opportunities for women of color in the workforce. We pair promising talent with female industry titans and entrepreneurs in the music, media, entertainment, technology, and social justice industries. Through a comprehensive year-long program, mentees will receive exposure to real-time projects, one-on-one coaching, resume building lessons, industry insights and expertise, and relationship building opportunities. The inaugural program will include 200 mentees who are women of color, with at least half of those participants being current students or alumni of Historically Black Colleges and Universities (HBCUs).Over 100 leading industry executives have signed on as mentors from an impressive list of companies including Amazon Music, Apple Music, iHeart Media, Atlantic Records, Capitol Music Group, Columbia Records, Endeavor, Clubhouse, ICM Partners, Interscope Geffen A&M, Live Nation, Pandora, SoundCloud, Spotify, TikTok, iHeartMedia, Revolt Media, Essence, WME, YouTube, and more. <br> This program relies solely on charitable donations and grants for its operations. <br><br><strong><a href="https://musicforwardfoundation.org/next-gem-femme/" class="btn" target="_blank"> Please click here to donate.</a></strong>`
}