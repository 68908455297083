import React from 'react';
import anime from 'animejs/lib/anime.es.js';

function animateEnterButton(scale, duration, elasticity, e) {
    anime.remove(e)
    anime({
        targets: e,
        scale: scale,
        duration: duration,
        elasticity: elasticity,
    });

    anime.remove(e.parentNode)
    anime({
        targets: e.parentNode,
        duration: duration,
        zIndex: {
            value: [1, 5],
            round: true
          }
    });
}
function animateLeaveButton(scale, duration, elasticity, e) {
    anime.remove(e)
    anime({
        targets: e,
        scale: scale,
        duration: duration,
        elasticity: elasticity,
    });

    anime.remove(e.parentNode)
    anime({
        targets: e.parentNode,
        duration: duration,
        zIndex: {
            value: [5, 1],
            round: true
          }
    });
}

    function enterButton(e) { 
        animateEnterButton(1.2, 800, 400, e) 
    };
    function leaveButton(e) { 
        animateLeaveButton(1.0, 600, 300, e) 
    };
    
    const TableItem = (props) => {
    return(
    <a id={props.title} onClick={props.onClick} className={`table-element table-circle ${props.title} ${props.className}`} href={props.href} target="_blank" style={{top: props.y, left: props.x}}>
        <img src={props.image} id={props.title}
            onTouchStart={(e) => enterButton(e.currentTarget)}
            onTouchEnd={(e) => leaveButton(e.currentTarget)}
            onMouseOver={(e) => enterButton(e.currentTarget)}
            onMouseOut={(e) => leaveButton(e.currentTarget)}
        />

        {props.tooltip && (<div ref={props.ref} className="table-point">
        <div id={props.title} className="table-dot"></div>
        <div id={props.title} className="table-point-ring ui-table-point-ring"></div>
        <p id={props.title} className="table-tooltip">
        <span id={props.title} className="table-tooltip-inner">{props.tooltip}</span>
        </p>
        </div>)}
        
    </a>
    )
}

export default TableItem