import React, {useRef, useEffect, useState} from "react"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"
import { useSpring, animated } from 'react-spring'


import Layout from "../components/layout"
import Image from "../components/image"
import GirlImage from "../components/girls"
import SEO from "../components/seo"

import frame9 from '../images/frame-9.png'
import frame12 from '../images/frame-12.png'
import logo from '../images/logo.png'

import LJ from '../images/LJ.png'
import TW from '../images/TW_.png'
import TW2 from '../images/TW-2.png'
import Rapsody from '../images/Rapsody.png'
import BR from '../images/BR.png'
import ML from '../images/ML_.png'
import Ambre from '../images/AMBRE.png'
import SH from '../images/SH.png'
import TP from '../images/TP.png'
import KL from '../images/KL.png'
import SAYGRACE from '../images/SAYGRACE.png';
import BK from '../images/BK.png'
import Asiahn from '../images/Asiahn.png'

import ipad from '../images/ipad.png'
import skates from '../images/skate.png'
import tv from '../images/tv.png'
import penPad from '../images/penpad.png'
import barbie from '../images/barbie.png'

import TableItem from "../components/table-item";

import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faCheckSquare, faCoffee } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import anime from 'animejs/lib/anime.es.js';
import {taylaParx, jadaKingdom, laurenJuaregui, kianaLede, RapsodyInfo, tierraWhack, rapsodyInfo, bbRose, doechiiInfo, ambreInfo, muniLongInfo, sineadHarnettInfo, sayGrace, bfeInfo, femmeitForwardInfo, tierraWhack2, asiahn, bkHabermehl} from '../data/artist';
import {lyrics} from '../data/lyrics/lyrics'

library.add(fab, faCheckSquare, faCoffee)

const imageRatio = 1 / 1;

const state = {
  mapWidth: 800,
  mapHeight: 600,
  innerX: 0,
  innerY: 0,
  positionIndicatorDotX: 0,
};

function IndexPage() {  
  const wrapper = useRef(null);
  const inner = useRef(null)
  const dot = useRef(null)
  const test = useRef(null)

    
  const isWindowWide = () => {
    if (typeof window === "undefined") return false
    const windowRatio = window.innerWidth / window.innerHeight
    return windowRatio < imageRatio
  }

  const renderMapSize = () => {
    let width;
    let height;

    if (isWindowWide()) {
      height = window.innerHeight;
      width = height * imageRatio;
    } else {
      width = window.innerWidth;
      height = width / imageRatio;
    }
    state.mapWidth = width;
    state.mapHeight = height;
    inner.current.style.width = `${width}px`;
    inner.current.style.height = `${height}px`;
    if (isTouchScreen()) {
      wrapper.current.scrollTo((width - window.innerWidth) / 2, 0);
    }
  }

  const isTouchScreen = () => {
    return (
      "ontouchstart" in window ||
      navigator.MaxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0
    );
  }

  function renderWrapperHeight() {
    // const { wrapper } = ui();
    renderMapSize();
  if(wrapper.current){
    wrapper.current.style.height = `${window.innerHeight}px`;
  }
  
    if (isTouchScreen()) {
      wrapper.current.style.height = `${window.innerHeight}px`;
    }
  }

  function renderInnerXY() {
    inner.current.style.transform = `translate(${state.innerX}px, ${state.innerY}px)`;
  }


  function renderPositionIndicatorDotX() {

    dot.current.style.transform = `translateX(${state.positionIndicatorDotX}px)`;
  }


  function handleWrapperScroll() {
    const maxScroll = state.mapWidth - window.innerWidth;
    const progress = wrapper.current.scrollLeft/ maxScroll;
    const translateX = 16 * progress;
    state.positionIndicatorDotX = translateX;
    renderPositionIndicatorDotX();
  }
  

  function handleResize() {
    if (!isTouchScreen()) {
      renderMapSize();
      state.innerX = 0;
      state.innerY = 0;
      renderInnerXY();
    }
  }


  useEffect(() => {
    renderMapSize();
    renderWrapperHeight();

    window.addEventListener("resize", () => {
      handleResize();
    });

    wrapper.current.addEventListener("scroll", () => {
      console.log('scrolling')
      handleWrapperScroll();
    });

    window.oncontextmenu = function(event) {
      event.preventDefault();
      event.stopPropagation();
      return false;
    };

  }, []);


const [modal, setModal] = useState(false)
const [modalData, setModalData] = useState({
  artist:'Tayla Parx',
  title:'Mother Nature',
  song_url:"https://open.spotify.com/embed/track/4jlFQMGIn42SphX3hk2r8l",
  image: LJ,
  desc:`GRAMMY® Award-nominated singer, songwriter, and boundary breaker Tayla Parx reimagines
  genre, gender, feminism, popular music, and the very definition of an artist. Within one year,
  she became “the first female songwriter to have three simultaneous top 10 songs in the
  Billboard Hot 100 since 2014.`
})
const toggleModal = (event) => {
  console.log(event)
  // setModalData(jadaKingdom)

  switch (event.id){
    case 'frame1':
      setModalData(laurenJuaregui);
      break;
    case 'frame2':
      setModalData(tierraWhack);
      break;
    case 'frame3':
      setModalData(rapsodyInfo);
      break; 
    case 'frame4':
    setModalData(bbRose);
    break; 
    case 'frame5':
    setModalData(tierraWhack2);
    break; 
    case 'frame6':
    setModalData(ambreInfo);
    break; 
    case 'frame7':
    setModalData(muniLongInfo);
    break; 
    case 'frame8':
    setModalData(sineadHarnettInfo);
    break; 
    case 'frame9':
    setModalData(taylaParx);
    break;     
    case 'frame10':
    setModalData(sayGrace);
    break;         
    case 'frame13':
      setModalData(kianaLede);
      break;
    case 'asiahn':
      setModalData(asiahn);
      break;
    case 'bkHabermehl':
      setModalData(bkHabermehl);
      break;
    case 'logo':
      setModalData(bfeInfo);
      break;
    case 'barbieImg':
      setModalData(femmeitForwardInfo);
      break;    
    default:
      console.log('default')
  }
  setModal(!modal)
}
  
  const closeModalOnClick = (e) => {
    if(e.target === e.currentTarget){
      console.log('closeModal')
      setModal(!modal)
      setModalData(bfeInfo)
    }
  }

  const closeModal = (e) => {
    setModal(!modal)
    setModalData(bfeInfo)
  }

  const Modal = (props) => (
    <div onClick={(e) => closeModalOnClick(e)} className={`modal ${props.open}`}>
    {modalData.image || modalData.webm ? (<div className={`modal-image`}>
      {modalData.image ? (<img src={modalData.image}></img>) : (<video muted autoPlay loop playsInline>
        <source src={modalData.webm} />
        <source src={modalData.hevc} />
      </video>)}
      </div>): ''}
      <div className={`modal-inner`}>
        <div className={`modal-close`} onClick={(e) => closeModal(e)}><a href="#">close x</a></div>
        <h2>{modalData.artist}</h2>       
        <h3>{modalData.title}</h3>       
        <p dangerouslySetInnerHTML={{__html: `${modalData.desc}`}} />
        <div>{modalData.artist == 'Big Femme Energy' ? <button className="btn" onClick={() => setLyricModal(!lyricModal)}><strong>Click here to view lyrics.</strong></button>: ''}</div>
        <br></br>
        {modalData.song_url && <iframe src={modalData.song_url} width="100%" height="80" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>}
        <br></br>
        <hr />
        </div>
    </div>
  )

  
  //lyrics modal
  const [lyricModal, setLyricModal] = useState(false)
  const [lyricData, setLyricData] = useState()
  
  const toggleLyricModal = (event) => {
    setLyricModal(!lyricModal)
  }

  const closeLyricModal = (e) => {
    setLyricModal(!lyricModal)
  }
  const closeLyricModalOnClick = (e) => {
    if(e.target === e.currentTarget){
      setLyricModal(!lyricModal)
    }
  }
  const backToLyrics = (e) =>{
    setLyricData()
  }

  const lyricsList = lyrics.map(lyric => (<li onClick={(e) => setLyricData(lyric)}><a><h2>{lyric.title}</h2><h3>{lyric.artist}</h3></a></li>))
  
  const LyricModal = (props) => (
    <div onClick={(e) => closeLyricModalOnClick(e)} className={`modal lyrics_modal ${props.open}`}>
    <div className={`modal-inner`}>
        <div className={`modal-close`} onClick={(e) => closeLyricModal(e)}><a href="#">close x</a></div>
        {!lyricData && <ul className={`lyrics_list`}>{lyricsList}</ul>}
        {lyricData && <div className={`modal-back`} onClick={(e) => backToLyrics(e)}><a href="#">Back to Lyrics</a></div>}
        {lyricData && <h3 className={`lyric-title`}>{lyricData.title} - {lyricData.artist} </h3>}
        {lyricData && <div className={`content`} dangerouslySetInnerHTML={{__html: `${lyricData.lyrics}`}}></div>}
        </div>
    </div>
  )

  return(
    
  <div>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Big Femme Energy</title>
      <meta name="description" content="Big Femme Energy, an all-female compilation album filled with affirmations of self-empowerment, autonomy and strength is Femme It Forward’s latest step towards fostering more inclusivity in the music."/>
      <link rel="canonical" href="https://bigfemmeenergy.com" />
      <script src="https://js.adsrvr.org/up_loader.1.1.0.js" async={false}></script>
      <script async={false}>
        {`
        window.addEventListener("load", function(){
          ttd_dom_ready( function() {
            if (typeof TTDUniversalPixelApi === 'function') {
            var universalPixelApi = new TTDUniversalPixelApi();
            universalPixelApi.init("wed77s2", ["q684oyg"], "https://insight.adsrvr.org/track/up");
            }
            });
        });
        `}
      </script>
    </Helmet>
    <div className={`table-container ${modal && 'blur'} ${lyricModal && 'blur'}`} data-component="table">
    <div ref={wrapper} className={`table-wrapper`}>
      <div className="table-preloader -hidden">
        <h2 className="table-preloader-title">Big Femme Energy</h2>
      </div>
      <div ref={inner} className="table-inner">
        <GirlImage />
        <Image />
        
        
        <div className="table-points">
        
          <TableItem onClick={(e) => toggleModal(e.target)} image={logo}  x={`3%`} y={`14%`} title={"logo"}/>
          <TableItem onClick={(e) => toggleModal(e.target)} image={LJ} x={`27%`} y={`2%`} title={"frame1"}/>
          <TableItem onClick={(e) => toggleModal(e.target)} image={KL} x={`27%`} y={`21%`} title={`frame13`}/>
          
          <TableItem onClick={(e) => toggleModal(e.target)} image={Asiahn} x={`45%`} y={`19%`} title={`asiahn`}/>
          <TableItem onClick={(e) => toggleModal(e.target)} image={BK} x={`63%`} y={`18%`} title={`bkHabermehl`}/>
          
          <TableItem onClick={(e) => toggleModal(e.target)} image={BR} x={`44%`} y={`5%`} title={`frame4`}/>
          <TableItem onClick={(e) => toggleModal(e.target)} image={Ambre} x={`47%`} y={`43%`} title={`frame6`}/>
          <TableItem onClick={(e) => toggleModal(e.target)} image={TW2} x={`28%`} y={`42%`} title={`frame5`}/>
          
          {/* <TableItem href={`https://www.youtube.com/channel/UCg5bM-YL_Alhilpha58bpZg/featured`} tooltip={`Watch Videos`} x={`5%`} y={`88%`} title={"ipad"} className={`link`} /> */}
          

          
          
          
          {/* <TableItem image={TW} onClick={(e) => toggleModal(e.target)} x={`0%`} y={`26%`} title={`frame2`}/> */}
          <TableItem onClick={(e) => toggleModal(e.target)} image={Rapsody} x={`60%`} y={`2%`} title={`frame3`}/>
          
          
          
          
          <TableItem onClick={(e) => toggleModal(e.target)} image={ML} x={`62%`} y={`37%`} title={`frame7`}/>
          <TableItem onClick={(e) => toggleModal(e.target)} image={SAYGRACE} x={`83%`} y={`21%`} title={`frame10`}/>
          <TableItem onClick={(e) => toggleModal(e.target)} image={SH} x={`82%`} y={`44%`} title={`frame8`}/>
          
          
          <TableItem onClick={(e) => toggleModal(e.target)} image={TP} x={`82%`} y={`1%`} title={`frame9`}/>
          
          <TableItem href={`https://www.femmeitforward.com/shop`} tooltip={`Merch`} x={`28.4%`} y={`80.2%`} title={"skates"} className={`link`} />
          {/* <TableItem onClick={(e) => toggleModal(e.target)} tooltip={`Next Gem Femme`} x={`28.4%`} y={`80.2%`} title={"barbieImg"}  className={`link`} /> */}
          {/* <TableItem onClick={(e) => toggleLyricModal(e.target)} tooltip={`Lyrics`} x={`81.5%`} y={`80.5%`} title={`lyricToggle`} className={`link`} /> */}
          <TableItem href={`https://www.femmeitforward.com`} tooltip={`Femme it Forward`} x={`65%`} y={`68.5%`} title={"link"}/>
          
          {/* <TableItem image={tv} x={`45.1%`} y={`45%`} title={`tvImg`}/> */}
        </div>

      </div>
      <div className="table-scroll-position-indicator">
        <div ref={dot} className="table-scroll-position-indicator-dot"></div>
      </div>
    </div>
  </div>

  <Modal open={(modal ? 'ModalOpen' : 'ModalClosed')} />
  <LyricModal open={(lyricModal ? 'ModalOpen' : 'ModalClosed')} />
  </div>
  )}

export default IndexPage
