export const atyourworst = {
    title:'At Your Worst',
    artist: 'Baby Rose',
    lyrics:`
    <p>Don’t go slamming the door  <br>
    baby, use your words <br>
    U can can let down your guard<br>
    When when you're feeling hurt </p>
    
    <p>Its like it’s like your fire doesn’t burn
    I can hold my you 
    even when you’re at your worst </p>
    
    <p>
    I don’t wanna scream in your face<br>
    I would rather scream out your name<br>
    Don’t let emotions  get in the way <br>
    Of all things you know you wanna say </p>
    
    Even if we're fighting <br>
    You know I'm fighting for you <br>
    <br>
    Might not get right but <br>
    U know I'm ridin for u <br>
    <br>
    If it's fight or flight  <br>
    I'll take it higher for u <br>
    <br>
    Cause Even if we're fighting<br>
    You know I'm fighting for you<br>
    
    <p>
    Wont you take ya clothes off<br>
    Strip down your pride<br>
    <br>
    Now we're Skin to skin<br>
    I'll let u inside<br>
    We got nothing to hide<br>
    <br>
    we don't need words to fill up the space <br>
    I'll Let your heartbeat set the pace<br>
    And that's how we'll have our way <br>
    We can both have our way <br>
    <br>
    I don’t wanna scream in your face<br>
    I would rather scream out your name<br>
    Don’t let emotions get in the way <br>
    Of all things you know you wanna say <br>
    <br>
    Even if we're fighting <br>
    You know I'm fighting for you <br>
    <br>
    Might not get right but<br>
    U know I'm ridin for u <br>
    <br>
    If it's fight or flight  <br>
    I'll take it higher for u <br>
    <br>
    Cause Even if we're fighting<br>
    You know I'm fighting for you<br>
    <br>
    U and me can break all the rules<br>
    knowing we got so much to lose<br>
    U know I'm fighting for ya <br>
    <br>
    We can go smoke on the roof<br>
    together we can shoot for the moon<br>
    You know I’m fighting for ya<br>
    <br>
    You ain't got nothing to prove <br>
    You can just take off your cool<br>
    You know I'm fighting for ya<br>
    <br>
    We could just break all the rules <br>
    it ain’t nothing without you <br>
    You know I'm fighting for you<br>
    <br>
    X2 with hook overlay <br>`
}