import { atyourworst } from "./atyourworst";
import { crownLyrics } from "./crown";

export const cutEmOffLyrics = {
    title:'Cut `Em Off',
    artist: 'Kiana Ledé',
    lyrics:`
    <p>What can I say<br>
I make it easy<br>
I wanna see you, I wanna see you on your knees<br>
In front of my face like that<br>
You testing my patience, yeah<br>
For something so basic, okay, okay then<br>
<br>
Ooh , yeah, yeah<br>
What a day, what a day<br>
Cause I give and you take<br>
And all you ever do is talk too much<br>
Can we be done?<br>
Today, goodbye, your time is up with me<br>
<br>
If it ain’t good enough<br>
Tell ’em you ain’t putting up<br>
I’m a bad bitch, I’m tough<br>
Only one that I love<br>
Run that shit into the floor<br>
Show that ass to the door<br>
Make it quick, cut’em off<br>
I don’t even think about it<br>
<br>
See, I just cut ’em off (2x)<br>
Yeah, I just cut ’em off<br>
Okay, see, I just cut it<br>
<br>
Different, I thought that this would be different<br>
That’s why I did it just to try it<br>
I guess that’s on me cause<br>
I know what it was, but I did it, I did it<br>
<br>
Ooh , yeah, yeah<br>
What a day, what a day<br>
Cause I give and you take<br>
And all you ever do is talk too much<br>
Can we be done?<br>
Today, goodbye, your time is up with me<br>

<br>
If it ain’t good enough<br>
Tell ’em you ain’t putting up<br>
I’m a bad bitch, I’m tough<br>
Only one that I love<br>
Run that shit into the floor<br>
Show that ass to the door<br>
Make it quick, cut’em off<br>
I don’t even think about it<br>
<br>
See, I just cut ’em off (2x)<br>
Yeah, I just cut ’em off<br>
See, I just cut it<br>
<br>
I just cut ’em off (2x)<br>
Said, I just cut ’em off<br>
I just cut ’em off (5x)<br>
I be cutting ‘em off<br>
We don’t gotta talk, I just cut ’em off, yeah<br>
<br>
If it ain’t good enough<br>
Tell’em you ain’t putting up<br>
I’m a bad bitch, I’m tough<br>
Only one that I love<br>
Run that shit into the floor<br>
Show that ass to the door<br>
Make it quick, cut’em off<br>
I don’t even think about it<br>
<br>
See, I just cut ’em off (2x)<br>
I just cut ’em off<br>
Yeah, I just cut ’em off<br>
<br>
I just cut it (2x)<br>
<br>
I just cut ’em off, ohh yeahh<br>
I just cut ’em off <br>
Said, I just cut it <br>
I just cut it, yeah</p>`
}

export const iconicLyrics = {
    title: 'Iconic',
    artist: 'Rapsody',
    lyrics: `Who you know been thing good for this long?<br>
    Upper echelon <br>
    Only inspired by the greats <br>
    The icons<br>
    <br>
    *ya bitch<br>
    <br>
    It’s big big drip<br>
    It’s so iconic<br>
    I think I’m Mary J<br>
    Always did it how I want it <br>
    It’s big big drip <br>
    It’s so iconic <br>
    Do your thing lil mama<br>
    Do your thing lil mama <br>
    <br>
    It’s big big drip<br>
    It’s so iconic<br>
    I think I’m Mary J<br>
    Always did it how I want it <br>
    It’s big big drip <br>
    It’s so iconic <br>
    Do your thing lil mama<br>
    Do your thing lil mama<br>
    <br>
    I think I’m Mary muthafuckin Blige<br>
    Niggas aint gon play me stupid<br>
    Bitches actin up for free<br>
    I only act up for the movies<br>
    I’m still the Queen <br>
    I been a G before I put on Gucci<br>
    Love me or hate me<br>
    Y’all stay fake mad more than Lil Boosie<br>
    Ask my Migos I ain’t got ego but I might be bougie<br>
    But it’s mixed wit hood I still put hot sauce on my two piece <br>
    My weave poppin<br>
    I got so many options<br>
    I might go blonde today<br>
    Or black it out like Onyx<br>
    Might take it back to bucket hats <br>
    All of my styles iconic <br>
    We black we speak Ebonics<br>
    Everything I’m on poppin <br>
    All week long we mobbin<br>
    Somebody hit up Fivi<br>
    I got that big drip size it <br>
    We only talk deposits <br>
    <br>
    It’s big big drip<br>
    It’s so iconic<br>
    I think I’m Mary J<br>
    Always did it how I want it <br>
    It’s big big drip <br>
    It’s so iconic <br>
    Do your thing lil mama<br>
    Do your thing lil mama <br>
    <br>
    It’s big big drip<br>
    It’s so iconic<br>
    I think I’m Mary J<br>
    Always did it how I want it <br>
    It’s big big drip <br>
    It’s so iconic <br>
    Do your thing lil mama<br>
    Do your thing lil mama<br>
    <br>
    I think I’m Mary MFn Blige<br>
    Body snatched, brown skin<br>
    The streets love me<br>
    Niggas sing my praises <br>
    Throw ya towels in<br>
    Ion sweat the drama<br>
    All my homegirls bad like Mees and Kim<br>
    My tribe don’t be trying dem<br>
    That’s messin with a lions dens<br>
    Dem broken hearts just made me richer <br>
    Turn my exes into crosses<br>
    Bitch this a God body<br>
    Made wins outta all my losses<br>
    I throw on da timbs and hoops On the roof singing you are all I<br>
    need this<br>
    S 6<br>
    Picture me rollin, sea sick<br>
    Never worried bout a wave<br>
    The ocean I’m the whole shit<br>
    If you insecure just say that<br>
    You ain’t gotta hate chick<br>
    No more hateration <br>
    go through all the stages of my life <br>
    Came out Feeling so amazing<br>
    I don’t even talk a price<br>
    <br>
    It’s big big drip<br>
    It’s so iconic<br>
    I think I’m Mary J<br>
    Always did it how I want it <br>
    It’s big big drip <br>
    It’s so iconic <br>
    Do your thing lil mama<br>
    Do your thing lil mama<br>
    <br>
    It’s big big drip<br>
    It’s so iconic<br>
    I think I’m Mary J<br>
    Always did it how I want it <br>
    It’s big big drip <br>
    It’s so iconic <br>
    Do your thing lil mama<br>
    Do your thing lil mama<br>`
}

export const iftherereallyisagodLyrics = {
    artist:'SAYGRACE',
    title:'If There Really Is A God',
    lyrics: `
    We've been through it all <br>
    Maybe now I'm grown <br>
    I'll be less naive <br>
    Thinking you meant me no harm <br>
    Nobody and no money could make me feel the way you do <br>
    But you're so far, out of reach <br>
    This ain't how it's supposed to be<br>
    <br>
    I have been wishing on a star <br>
    I have been manifesting <br>
    I have been praying in the dark <br>
    I have been wondering <br>
    If there really is a God <br>
    Then why would she do this to me 	<br>
    <br>
    It's so cruel, <br>
    To fill my heart with fire that only burns for you <br>
    And it's so unfair that I was so close that I could touch it<br>
    But never own it<br>
    Nobody and no money can break me down <br>
    The way you do <br>
    But I'm so scared because I don't know who I am without you<br>
    <br>
    I have been wishing on a star <br>
    I have been manifesting <br>
    I have been praying in the dark <br>
    I have been wondering <br>
    If there really is a God <br>
    Then why would she do this to me <br>
    <br>
    Have you ever loved somebody so bad It makes you cry? <br>
    Have you ever needed something so bad you can't sleep at night? <br>
    <br>
    If there really is a God then why would she do this to me <br>
    To me<br>
    Have you ever loved somebody so bad it makes you cry? <br>
    Have you ever needed something so bad you can't sleep at night? <br>
    If there really is a God <br>
    Then why would she do this to me <br>
    To Me<br>
    <br>
    I have been wishing on a star <br>
    I have been praying in the dark <br>
    Have you ever loved someone so bad? <br>
    If there really is a God <br>`
}

export const whileImAliveLyrics = {
    artist: 'Lauren Jauregui',
    title: "While I'm Alive",
    lyrics: `If I can’t be me,<br>
    Who do you want me to be?<br>
    Cause if you can’t see beyond your gaze,<br>
    You don’t phase me and I don’t trust you anyway<br>
    Cause running through life I <br>
    figured out time don’t wait for no one <br>
    So while I’m alive <br>
    Imma just be me<br>
    Whoever that ends up being...<br>
    <br>
    Don’t say I’m too loud<br>
    When I cry out<br>
    What I’ve held inside<br>
    I wanna know why they try to take me and bury me alive.<br>
    Cause who am I <br>
    to think that I have the right or the sight<br>
    to know what I need, right?<br>
    It’s in the way<br>
    I talk and I walk away<br>
    from what you made me<br>
    <br>
    If I can’t be me,<br>
    who do you want me to be?<br>
    Cause if you can’t see beyond your gaze, you don’t phase me and I don’t trust you anyway.<br>
    Cause running through life, I figured out time don’t <br>
    Wait for no one so while I’m alive <br>
    Imma just be me <br>
    Whoever that ends up being...<br>
    <br>
    And I know this is all too familiar.<br>
    Feel the motive behind all that fake love.<br>
    I can’t take all these unsolicited opinions,<br>
    go ahead and be the scared ones<br>
    either way I’ll get it done<br>
    I’ve been told<br>
    I’m too much,<br>
    Too proud,<br>
    Too crude <br>
    And too wild <br>
    Difficult to be around <br>
    With too much to say<br>
    Never in the right way<br>
    So what’s the right way? <br>
    <br>
    If I can’t be me,<br>
    who do you want me to be?<br>
    Cause if you can’t see beyond your gaze, you don’t phase me and I don’t trust you anyway.<br>
    Cause running through life, I figured out time don’t <br>
    Wait for no one so while I’m alive <br>
    Imma just be me <br>
    Whoever that ends up being..<br>
    <br>
    <br>
    If I can’t be me,<br>
    who do you want me to be?<br>
    Cause if you can’t see beyond your gaze, you don’t phase me and I don’t trust you anyway.<br>
    Cause running through life, I figured out time don’t <br>
    Wait for no one so while I’m alive <br>
    Imma just be me <br>
    Whoever that ends up being...<br>`
}
export const motherNatureLyrics = {
    title: 'Mother Nature',
    artist: "Tayla Parx",
    lyrics : `You see it in my walk and you can hear it in my talk <br>
    The way the fragrance linger know you’re fucking with a boss <br>
    If you had me then you lost me take the L cause that’s a loss <br>
    Serving up the slay it’s Smothered in the sauce <br>
    <br>
    <br>
    Woman is life <br>
    Woman is love <br>
    Different alike <br>
    But Woman is us <br>
    You know what it is <br>
    You know what it was <br>
    Doing the most is nothing to us <br>
    Ahhh<br>
    <br>
    <br>
    They say that this been a mans world<br>
    But it wouldn’t be nothing not without a girl <br>
    X2<br>
    <br>
    They say that this been a mans world<br>
    But it wouldn’t be nothing not without a girl <br>
    <br>
    <br>
    I’m freer the wind <br>
    & i can always stand the rain<br>
    A diamond in rough and one of just aint the same<br>
    Tell me who else do you know creating life out of the pain<br>
    Shoutout to ya mama betta say her name<br>
    <br>
    Woman is life <br>
    Woman is love <br>
    Different alike <br>
    But Woman is us <br>
    You know what it is <br>
    You know what it was <br>
    Doing the most is nothing to us <br>
    Ahhh<br>
    <br>
    <br>
    They say that this been a mans world<br>
    But it wouldn’t be nothing not without a girl <br>
    X2<br>
    <br>
    They say that this been a mans world<br>
    But it wouldn’t be nothing not without a girl <br>
    <br>`
}
export const plainJaneLyrics = {
    title: 'Plain Jane',
    artist: 'Muni Long',
    lyrics: `No make up <br>
    No lacefront<br>
    Just lay back<br>
    In my wig cap<br>
    Ay-<br>
    Just plain jane<br>
    Same ol same <br>
    Lookin plain jane<br>
    Nigga you not finna tell me<br>
    You can not see all this beauty <br>
    Even with the set it off braids <br>
    i know damn well ima cutie<br>
    Headscarf with a dubie<br>
    Eatin popeyes yeah the two piece<br>
    Mix that lemonade with a cup of grape koolaid<br>
     <br>
    No make up <br>
    No lacefront<br>
    Just lay back<br>
    In my wig cap<br>
    Ay-<br>
    Just plain jane<br>
    Same ol same <br>
    Lookin plain jain<br>
     <br>
    Plain jane <br>
    Aint gone have no shame<br>
    I know what i look like when i put on all my chains<br>
    Plaine jane<br>
    Never had no shame <br>
    I know what i look like when i put on all my thangs <br>
     <br>
    No make up <br>
    No lacefront<br>
    Just lay back<br>
    In my wig cap<br>
    Ay-<br>
    Just plain jane<br>
    Same ol same <br>
    Lookin plain jain<br>
     <br>
    Regular degular *3<br>
    Regular degular shmegular<br>`
}
export const whatYouDeserveLyrics = {
    artist:"Ambré feat. 6lack",
    title: "What You Deserve Remix" ,
    lyrics : `Say why would you wait<br>
    For something fake don't you want the real thing<br>
    Get what you deserve <br>
    Can't nobody take what God put in front of you  <br>
    So when you ready to flourish come get<br>
    What you deserve (you deserve, you deserve, yea, you deserve)<br>
    What you deserve (you deserve, what you deserve)<br>
    Come and get what you deserve (yea, you deserve, get your piece)<br>
    <br>
    Say last time you came <br>
    asking for research purpose <br>
    it’s not a game<br>
    I will not stand by and let you crash out <br>
    Know what you deserve, You deserve to pass out <br>
    Gotta let your imagination run wild <br>
    Stayed down for a minute but we up now <br>
    Anytime I see ya hallelujah to the heavens<br>
    Where your bredren here yo lesson<br>
    Come and get your blessing <br>
    Don’t you want a spa day, wanna be my date <br>
    Texting on Thursday, Sexing on Friday <br>
    I’m trying to find out what your body and your mind say <br>
    Even robbing you with joy boosting the crime rate<br>
    Come and get what you deserve <br>
    <br>
    what you deserve <br>
    Come and get what you deserve <br>`
}

export const whoKnewLyrics = {
    artist:"Tierra Whack",
    title: "Who Knew",
    lyrics: `Who knew<br>
    You were a liar<br>
    You knew<br>
    That I’d get tired<br>
    Who knew it<br>
    You knew It<br>
    Who knew it<br>
    You knew it<br>
    Who knew it<br>
    You knew it <br>
    <br>
    You knew it <br>
    <br>
    Who knew<br>
    You were a liar<br>
    You knew<br>
    That I’d get tired<br>
    Who knew it<br>
    You knew It<br>
    Who knew it<br>
    You knew it<br>
    Who knew it<br>
    You knew it <br>
    <br>
    Tell me why you didn’t keep your promise<br>
    Tell me why you stray from being honest<br>
    You thought you could keep me cause you buy diamonds<br>
    No<br>
    They take it for weakness when you show kindness<br>
    I got other options <br>
    They in my pocket<br>
    You witnessed my glow up<br>
    And tried to stop it …<br>
    I thought I was trippin<br>
    I changed the climate <br>
    I thought I had lost it <br>
    But I still got it …<br>
    Switch it up<br>
    I need a new man<br>
    I wonder who can<br>
    Be the one the I can lean on<br>
    I know that you can’t <br>
    Wave goodbye <br>
    Cause I’m so happy <br>
    I do it with two hands<br>
    <br>
    Wave goodbye <br>
    Cause I’m so happy <br>
    I do it with two hands<br>
    <br>
    Who knew<br>
    You were a liar<br>
    You knew<br>
    That I’d get tired<br>
    Who knew it<br>
    You knew It<br>
    Who knew it<br>
    You knew it<br>
    Who knew it<br>
    You knew it <br>
    <br>
    Who knew<br>
    You were a liar<br>
    You knew<br>
    That I’d get tired<br>
    Who knew it<br>
    You knew It<br>
    Who knew it<br>
    You knew it<br>
    Who knew it<br>
    You knew it <br>`
}

export const yourWindowLyrics = {
    artist:'Jordan Hawkins & B.K. Habermehl',
    title:'Your Window',
    lyrics:`Verse 1: Jordan Hawkins<br>
    <br>
    Nights like this<br>
    I think bout<br>
    Singing at your window<br>
    Girl don’t make me climb this shit<br>
    I need a rocket ship<br>
    Lost my keys, that’s out of pocket, I know Launch that shit<br>
    Baby launch that shit<br>
    <br>
    Way up there<br>
    Beyond the clouds<br>
    Beyond the sound<br>
    Help me get up off the ground<br>
    Yeah<br>
    <br>
    Chorus: Jordan Hawkins & B.K. Habermehl<br>
    It’s too late to go away from you Send me up the elevator<br>
    Don’t sleep till dawn breaks us Just let me up<br>
    I know you’re up<br>
    It’s too late to go away from you Send me up the elevator<br>
    Don’t sleep till dawn breaks us Just let me up<br>
    I know you’re up<br>
    Verse 2: B.K. Habermehl<br>
    Yea, yea<br>
    I’m elevated<br>
    All laced up<br>
    High on temptation<br>
    I saw the future in my crystal ball<br>
    When the clock strikes I like<br>
    When you put me on top<br>
    Come over tonight<br>
    Yeah that’s nice<br>
    Got me seeing stars<br>
    Might just hit Mars<br>
    In a minute<br>
    Get your tickets<br>
    Hop a G6 into my feelings<br>
    Hit my spirit<br>
    Can we listen to Marvin when we’re finished I like a smooth kind of lover<br>
    <br>
    Chorus: Jordan Hawkins & B.K. Habermehl<br>
    It’s too late to go away from you<br>
    Send me up the elevator (Send, send me up) Don’t sleep till dawn breaks us<br>
    Just let me up<br>
    I know you’re up (Just let me up, I know you’re up, I know you’re up) It’s too late to go away from you<br>
    Send me up the elevator (Hey)<br>
    Don’t sleep till dawn breaks us<br>
    Just let me up<br>
    I know you’re up<br>
    Bridge: Jordan Hawkins<br>
    Hand me your hair<br>
    Whisper the code<br>
    You are the gate keeper<br>
    Help me get up off the ground Hand me your hair<br>
    Whisper the code<br>
    You are the gate keeper<br>
    Help me get up off the ground<br>
    Goddamn, goddamn, goddamn<br>
    <br>
    Chorus: Jordan Hawkins & B.K. Habermehl<br>
    It’s too late to go away from you<br>
    Send me up the elevator (Send, send me up)<br>
    Don’t sleep till dawn breaks us<br>
    Just let me up<br>
    I know you’re up (Just let me up, I know you’re up, I know you’re up) It’s too late to go away from you<br>
    Send me up the elevator (Hey)<br>
    Don’t sleep till dawn breaks us<br>
    Just let me up<br>
    I know you’re up<br>
    Just let me up, I know you’re up, I know you’re up Goddamn, goddamn, goddamn<br>`,
}

export const whatSheWantsLyrics = {
    artist:'Asiahn ft. Elhae',
    title: 'What She Wants',
    lyrics:`Girl you bad <br>
    You the shit <br>
    From your head<br>
    To your kicks <br>
    You and your girls <br>
    Gettin lit <br>
    Imma drink this 1942 like this <br>
    We in the club <br>
    Taking pics <br>
    Standing on the couches <br>
    Let me get a kiss<br>
    Yo man at home <br>
    Oh, you think u slick <br>
    If he catch you out <br>
    He gon catch a fit <br>
    But she do what she want <br>
    She do what she want <br>
    Girl you know you wrong <br>
    But she do what she wants <br>
    Ay ay awww <br>
    <br>
    I got a girl<br>
    From round the way <br>
    Every time I pull up<br>
    She just want me to stay<br>
    Pretty round brown peach <br>
    From the A<br>
    We keep it low <br>
    No face no case <br>
    She been swearing off these niggas since she met me she say I’m the one <br>
    Want me to be the only one<br>
    Everytime I leave She miss the way I hit it <br>
    Knock it out the park <br>
    I wasnt tryna fall in Love<br>
    But she get what she wants<br>
    <br>
    Girl you bad <br>
    You the shit <br>
    From your head<br>
    To your kicks <br>
    You and your girls <br>
    Gettin lit <br>
    Imma drink this 1942 like this <br>
    We in the club <br>
    Taking pics <br>
    Standing on the couches <br>
    Let me get a kiss<br>
    Yo man at home <br>
    Oh, you think u slick <br>
    If he catch you out <br>
    He gon catch a fit <br>
    But she do what she want <br>
    She do what she want <br>
    Girl you know you wrong <br>
    But she do what she wants <br>
    Ay ay awww <br>
    <br>
    I don’t even really think I’m wrong <br>
    But im still not tryna go home <br>
    Let’s just drop the top and hit the road <br>
    I never kiss and tell that’s the code <br>
    You kno I ain’t pressed about no bitches <br>
    And I ain’t never pressed about them hoes <br>
    And every time I talk you like to listen <br>
    And that’s the quickest way to my soul <br>
    I ain’t really tryna be alone now <br>
    Girl I’m tryna be with you <br>
    So what you wanna do <br>
    <br>
    But she do what she want <br>
    She do what she want <br>
    Girl you know you wrong <br>
    But she do what she wants <br>
    Ay ay awww <br>
    `
}

export const lyrics = [
    motherNatureLyrics, 
    whileImAliveLyrics,
    whoKnewLyrics,
    iconicLyrics,
    cutEmOffLyrics,
    iftherereallyisagodLyrics,
    atyourworst,
    plainJaneLyrics,
    crownLyrics,
    whatSheWantsLyrics,
    yourWindowLyrics,
    whatYouDeserveLyrics
]