export const crownLyrics = {
    title:'Crown',
    artist: 'Sinéad Harnett',
    lyrics:`
    <p>
    She wakes up early morn’<br>
    Puts her makeup on<br>
    Looks herself in the mirror<br>
    Says “girl be strong”<br>
    Underneath the surface <br>
    She feels empty<br>
    <br>
    She wish she could undo life <br>
    Turn her wrongs to right<br>
    Take back the body she caught last night<br>
    Wake up and tell herself <br>
    It was all a dream<br>
    <br>
    You gotta tell yourself you’re better <br>
    'Cuz you’ve been running from yourself too long<br>
    You gotta know inside you’ve always been enough<br>
    Just been wearing your crown all wrong <br>
    You’re more than enough, babe<br>
    You’re more than enough<br>
    You gotta know inside you’ve always been enough<br>
    Just been wearing your crown all wrong<br>
    <br>
    Hey young girl, love your body <br>
    Keep your head, you're somebody<br>
    Don’t let them get in your way from calling yourself a queen<br>
    <br>
    She comes home late at night<br>
    Underneath moonlight<br>
    She knows that the stars only shine sometimes<br>
    For her they don’t align<br>
    She can hardly sleep<br>
    
    You gotta tell yourself you’re better <br>
    'Cuz you’ve been running from yourself too long<br>
    You gotta know inside you’ve always been enough<br>
    Just been wearing your crown all wrong <br>
    You’re more than enough, babe<br>
    You’re more than enough<br>
    You gotta know inside you’ve always been enough<br>
    Just been wearing your crown all wrong<br>
    <br>
    Hey young girl, love your body <br>
    Keep your head, you're somebody<br>
    Don’t let them get in your way from calling yourself a queen (x3)<br>
    […]<br>
    Queen </p><br>`
}